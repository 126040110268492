export function formatIndianStyle(value) {
	// Check if the value contains any alphabetic characters or is a date-like string
	if (/[a-zA-Z]/.test(value)) {
		return value;
	}

	// Check if it's a date-like format, e.g., 25-12-2024
	const datePattern = /^\d{1,2}-\d{1,2}-\d{4}$/;
	if (datePattern.test(value)) {
		return value;
	}

	// First, remove any commas from the input if it's in string form
	let numStr = value.toString().replace(/,/g, '');

	// Ensure that the value is a valid number (it might be a float)
	if (isNaN(numStr)) {
		return value;  // If it's not a valid number, return the input as is
	}

	// Handle negative numbers by storing the negative sign separately
	let isNegative = false;
	if (numStr[0] === '-') {
		isNegative = true;
		numStr = numStr.slice(1);  // Remove the negative sign temporarily for formatting
	}

	// Split the number into integer and decimal parts
	let [integerPart, decimalPart] = numStr.split('.');

	// Declare integerFormatted
	let integerFormatted;

	// Handle the integer part for Indian style formatting
	if (integerPart.length > 3) {
		let lastThree = integerPart.slice(-3);  // Last 3 digits (thousands part)
		let rest = integerPart.slice(0, integerPart.length - 3);  // All digits except the last 3
		rest = rest.replace(/\B(?=(\d{2})+(?!\d))/g, ',');  // Add commas every 2 digits to the remaining part
		integerFormatted = rest + ',' + lastThree;  // Combine the two parts
	} else {
		integerFormatted = integerPart;  // No comma needed for numbers with 3 or fewer digits
	}

	// If the number was negative, add the negative sign back
	if (isNegative) {
		integerFormatted = '-' + integerFormatted;
	}

	// If there's a decimal part, add it back
	if (decimalPart) {
		return integerFormatted + '.' + decimalPart;
	}

	return integerFormatted;
}