import { Typography } from "antd";

// Assets
import logo from './assessts/logo.jpg';


function Header({ hideHeader = false }) {
	const title = "Digi Yatra";

	return (
		<div
			style={{
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				marginBottom: "20px",
				backgroundColor: "rgb(59 130 246)",
				padding: '12px',
				borderRadius: '5px 27px',
				boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
			}}
		>
			<Typography.Title level={4} style={{ margin: 0, marginLeft: 20, color: "#FFF", fontWeight: "bold" }}>
				{title}
			</Typography.Title>

			<img
				src={logo}
				alt="CardImage"
				style={{
					width: 100,
					height: 30,
					scale: '1.5',
					objectFit: "contain",
					borderRadius: '2px 15px',
					background: 'white',
					marginRight: "17px"
				}}
			/>
		</div>
	);
}

export default Header;
