import React, { useState, useEffect } from 'react';
import { Table, DatePicker, Space, Pagination, Select, Button } from 'antd';
import { formatIndianStyle } from './res';
import { API_DATA } from './CONFIG';
import { Option } from 'antd/es/mentions';
import Header from './Header';

const { RangePicker } = DatePicker;

const fetchData = async (dateRange, year, month, current, pageSize) => {
	try {
		const response = await fetch(API_DATA, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				date_range: dateRange,
				year: year,
				month: month,
				page: current,
				page_size: pageSize,
			}),
		});
		const data = await response.json();
		return data;
	} catch (error) {
		console.error('Error fetching data:', error);
		return [];
	}
};

const App = () => {
	const [data, setData] = useState([]);
	const [selectedYear, setSelectedYear] = useState(null);
	const [selectedMonth, setSelectedMonth] = useState(null);
	const [loading, setLoading] = useState(true);
	const [selectedDateRange, setSelectedDateRange] = useState(null);
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 50,
		total: 0,
		totalPages: 0,
	});

	useEffect(() => {
		const loadData = async () => {
			setLoading(true);
			const result = await fetchData(
				selectedDateRange,
				selectedYear,
				selectedMonth,
				pagination.current,
				pagination.pageSize
			);
			setData(result.data);
			setPagination((prevState) => ({
				...prevState,
				total: result.total_records,
				totalPages: result.total_pages,
			}));
			setLoading(false);
		};

		loadData();
	}, [selectedDateRange, selectedYear, selectedMonth, pagination.current, pagination.pageSize]);

	const handleDateChange = (dates) => {
		setSelectedDateRange(dates ? [dates[0].format('YYYY-MM-DD'), dates[1].format('YYYY-MM-DD')] : null);
		setSelectedYear(null);
		setSelectedMonth(null);
	};

	const handleYearChange = (year) => {
		setSelectedYear(year);
		setSelectedDateRange(null);
	};

	const handleMonthChange = (month) => {
		setSelectedMonth(month);
		setSelectedDateRange(null);
	};

	const handlePaginationChange = (page, pageSize) => {
		setPagination((prevState) => ({
			...prevState,
			current: page,
			pageSize: pageSize,
		}));
	};

	const handleClearFilters = () => {
		setSelectedDateRange(null);
		setSelectedYear(null);
		setSelectedMonth(null);
	};

	const columns = [
		{
			title: 'Date',
			dataIndex: 'date',
			align: "right",
			sorter: (a, b) => {
				if (a.date === "TOTAL" || b.date === "TOTAL") {
					return 0;
				}
				return new Date(a.date) - new Date(b.date)
			},
			render: (text) => {
				if (text === "TOTAL") {
					return text
				}
				const date = new Date(text);
				const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
				const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (0-based, so add 1)
				const year = date.getFullYear();
				return `${day}-${month}-${year}`;
			}
		},
		{
			title: 'Old BC VC Success',
			dataIndex: 'old_bc_vc_issued_success_count',
			align: "right",
			sorter: (a, b) => {
				if (a.date === "TOTAL" || b.date === "TOTAL") {
					return 0;
				}
				return a.old_bc_vc_issued_success_count - b.old_bc_vc_issued_success_count
			},
			render: (text) => formatIndianStyle(text)
		},
		{
			title: 'Old BC VC Fail',
			dataIndex: 'old_bc_vc_issued_fail_count',
			align: "right",
			sorter: (a, b) => {
				if (a.date === "TOTAL" || b.date === "TOTAL") {
					return 0;
				}
				return a.old_bc_vc_issued_fail_count - b.old_bc_vc_issued_fail_count
			},
			render: (text) => formatIndianStyle(text)
		},
		{
			title: 'New BC VC Success',
			dataIndex: 'new_bc_vc_issued_success_count',
			align: "right",
			sorter: (a, b) => {
				if (a.date === "TOTAL" || b.date === "TOTAL") {
					return 0;
				}
				return a.new_bc_vc_issued_success_count - b.new_bc_vc_issued_success_count
			},
			render: (text) => formatIndianStyle(text)
		},
		{
			title: 'New BC VC Fail',
			dataIndex: 'new_bc_vc_issued_fail_count',
			align: "right",
			sorter: (a, b) => {
				if (a.date === "TOTAL" || b.date === "TOTAL") {
					return 0;
				}
				return a.new_bc_vc_issued_fail_count - b.new_bc_vc_issued_fail_count
			},
			render: (text) => formatIndianStyle(text)
		},
		{
			title: 'Migrated Success',
			dataIndex: 'migated_old_bc_vc_to_new_success',
			align: "right",
			sorter: (a, b) => {
				if (a.date === "TOTAL" || b.date === "TOTAL") {
					return 0;
				}
				return a.migated_old_bc_vc_to_new_success - b.migated_old_bc_vc_to_new_success
			},
			render: (text) => formatIndianStyle(text)
		},
		{
			title: 'Migrated Fail',
			dataIndex: 'migated_old_bc_vc_to_new_fail',
			align: "right",
			sorter: (a, b) => {
				if (a.date === "TOTAL" || b.date === "TOTAL") {
					return 0;
				}
				return a.migated_old_bc_vc_to_new_fail - b.migated_old_bc_vc_to_new_fail
			},
			render: (text) => formatIndianStyle(text)
		},
		{
			title: 'Direct Aadhar',
			dataIndex: 'direct_aadhar',
			align: "right",
			sorter: (a, b) => {
				if (a.date === "TOTAL" || b.date === "TOTAL") {
					return 0;
				}
				return a.direct_aadhar - b.direct_aadhar
			},
			render: (text) => formatIndianStyle(text)
		},
		{
			title: 'Digilocker',
			dataIndex: 'digilocker',
			align: "right",
			sorter: (a, b) => {
				if (a.date === "TOTAL" || b.date === "TOTAL") {
					return 0;
				}
				return a.digilocker - b.digilocker
			},
			render: (text) => formatIndianStyle(text)
		},
		{
			title: 'Total VC Success',
			dataIndex: 'total_vc_stats_success',
			align: "right",
			sorter: (a, b) => {
				if (a.date === "TOTAL" || b.date === "TOTAL") {
					return 0;
				}
				return a.total_vc_stats_success - b.total_vc_stats_success
			},
			render: (text) => formatIndianStyle(text)
		},
		{
			title: 'Total VC Fail',
			dataIndex: 'total_vc_stats_fail',
			align: "right",
			sorter: (a, b) => {
				if (a.date === "TOTAL" || b.date === "TOTAL") {
					return 0;
				}
				return a.total_vc_stats_fail - b.total_vc_stats_fail
			},
			render: (text) => formatIndianStyle(text)
		},
	];

	return (
		<div style={{ padding: '10px' }}>
			<Header />
			<Space direction="horizontal" style={{ width: '100%', marginBottom: '20px' }}>
				<RangePicker onChange={handleDateChange} />
				<Select placeholder="Select Year" onChange={handleYearChange} value={selectedYear} style={{ width: 120 }}>
					{[2025].map((year) => (
						<Option key={year} value={year}>
							{year}
						</Option>
					))}
				</Select>
				<Select placeholder="Select Month" onChange={handleMonthChange} value={selectedMonth} style={{ width: 120 }}>
					{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => (
						<Option key={month} value={month}>
							{month}
						</Option>
					))}
				</Select>
				<Button onClick={handleClearFilters} style={{ marginLeft: 10 }}>
					Clear Filters
				</Button>
			</Space>

			<Table
				columns={columns}
				dataSource={data}
				loading={loading}
				rowKey="date"
				pagination={false}
				scroll={{ x: 'max-content' }}
				className="shadow"
				size="middle"
				showSorterTooltip={false}
			/>

			<Pagination
				current={pagination.current}
				pageSize={pagination.pageSize}
				total={pagination.total}
				onChange={handlePaginationChange}
				showSizeChanger
				pageSizeOptions={['50', '100', '200']}
				style={{ marginTop: '10px' }}
			/>
		</div>
	);
};

export default App;
